export default class Config {
  static apiBaseUrl(url) {
    const uiEnvironment = url || window.location.hostname; // note url normally isn't passed but used for testing

    if (uiEnvironment.includes('localhost')) {
      return process.env.REACT_APP_USE_LOCAL_API === 'true'
        ? Config.APIURL.local
        : Config.APIURL.dev;
    }
    if (uiEnvironment.includes('dev')) {
      return Config.APIURL.dev;
    }
    if (uiEnvironment.includes('test')) {
      return Config.APIURL.test;
    }
    if (uiEnvironment.includes('production')) {
      return Config.APIURL.production;
    }
    return Config.APIURL.prod;
  }

  static invoiceApiUrl(customerId, invoiceNumber) {
    if (process.env.REACT_APP_USE_MOCKS === 'true') {
      return '/__fixtures__/invoices.json';
    }
    return `${Config.apiBaseUrl()}/customer/${customerId}/invoice?invoice_number=${invoiceNumber}`;
  }

  static cyberSourceDataApiUrl() {
    if (process.env.REACT_APP_USE_MOCKS === 'true') {
      return '/__fixtures__/customer_payment.json';
    }
    return `${Config.apiBaseUrl()}/customer_payment`;
  }

  static cyberSourceDataApiMethod() {
    if (process.env.REACT_APP_USE_MOCKS === 'true') {
      return 'get';
    }
    return `post`;
  }

  static confirmationApiUrl(confirmationCode) {
    if (process.env.REACT_APP_USE_MOCKS === 'true') {
      return '/__fixtures__/confirmation.json';
    }
    return `${Config.apiBaseUrl()}/payments/${confirmationCode}`;
  }

  static confirmationApiMethod() {
    if (process.env.REACT_APP_USE_MOCKS === 'true') {
      return 'get';
    }
    return `get`;
  }

  static emailReceiptApiUrl() {
    if (process.env.REACT_APP_USE_MOCKS === 'true') {
      return '/__fixtures__/email.json';
    }
    return `${Config.apiBaseUrl()}/email_receipt`;
  }

  static emailReceiptApiMethod() {
    if (process.env.REACT_APP_USE_MOCKS === 'true') {
      return 'get';
    }
    return `post`;
  }

  static getErrorMessageByCode(error) {
    return Config.ERROR_CUSTOM[error.code] === undefined
      ? error.message
      : Config.ERROR_CUSTOM[error.code];
  }

  static getErrorMessageByStatus(statusCode) {
    return Config.ERROR_CUSTOM[statusCode] === undefined
      ? Config.ERROR_GENERAL
      : Config.ERROR_CUSTOM[statusCode];
  }
}

Config.APIURL = {
  local: 'http://localhost:3000',
  dev: 'https://dev.api.arpayment.umn.edu',
  test: 'https://test.api.arpayment.umn.edu',
  prod: 'https://api.arpayment.umn.edu',
  production: 'https://production.api.arpayment.umn.edu',
};

Config.ERROR_CUSTOM = {
  E01: 'Customer not found, please input your valid customer ID. If you need assistance, contact <a class="alert-link" href="mailto:arpaymnt@umn.edu">arpaymnt@umn.edu</a>',
  E02: 'Invoice not found, please input your valid invoice number. If you need assistance, contact <a class="alert-link" href="mailto:arpaymnt@umn.edu">arpaymnt@umn.edu</a>',
  E03: ' The customer or invoice number you provided does not match our records, please try again. If you need assistance, contact <a class="alert-link" href="mailto:arpaymnt@umn.edu">arpaymnt@umn.edu</a>',
  E04: 'Invoice selection error, please navigate back to <a class="alert-link" href="https://pay.umn.edu">https://pay.umn.edu</a>',
  E05: 'Missing invoice error, please navigate back to  <a class="alert-link" href="https://pay.umn.edu">https://pay.umn.edu</a>',
  E06: '<p>We\'re sorry, but your payment could not be processed.</p><p>There are many possible reasons your payment was not able to be processed:</p><ul><li>Your card number, expiration date, and CVN code could have been entered incorrectly</li><li>The address you entered does not match the address on file with your financial institution</li><li>You might not have enough funds in your account to make the payment or you have reached your credit limit</li><li>Your card may not be authorized for Merchant Category Code (MCC) 8220 Universities, Colleges, or Professional Schools</li></ul><p>You may try your payment again, use a different card, or contact our office for additional help.</p><a class="btn btn-success" href="/customer">Continue</a>',
  E07: 'Missing email address, please provide a valid email address',
  E08: 'Invoice not available for payment',
  E09: 'Invoice not payable',
  E10: 'We are having problems talking to the payment service. If you need assistance, contact <a class="alert-link" href="mailto:arpaymnt@umn.edu">arpaymnt@umn.edu</a>',
  404: 'Missing customer, invoice, or payment error, please navigate back to  <a class="alert-link" href="https://pay.umn.edu">https://pay.umn.edu</a>',
  500: 'Site temporarily unavailable, try again soon',
};
Config.ERROR_GENERAL = 'Site temporarily unavailable, try again soon';

Config.PaymentStatus = {
  ERROR: 'ERROR',
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  CANCEL: 'CANCEL',
};
