import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import {
  NavLink,
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';
// HashRouter as Router // Good for when you can't control the URL ... like GitHub pages

import { UmnHeader, UmnFooter } from '@dobe0002/umn-web-template-react';

import Version from './Pages/Version';
import Confirmation from './Pages/Confirmation';
import FourOhFour from './Pages/FourOhFour';
import ContactFooter from './ContactFooter';
import Home from './Pages/Home';

export default class App extends React.Component {
  static buildNav() {
    return (
      <nav className='no-print'>
        <Nav>
          <NavItem>
            <NavLink
              activeClassName='active'
              className='nav-link'
              to='/version'
            >
              Version
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink activeClassName='active' className='nav-link' to='/'>
              Select Customer
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              activeClassName='active'
              className='nav-link'
              to='/customer/1234/invoices/7048555632'
            >
              Sample list of invoices
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              activeClassName='active'
              className='nav-link'
              to='/payments/12345'
            >
              Sample confirmation
            </NavLink>
          </NavItem>
        </Nav>
      </nav>
    );
  }

  render() {
    const basename = '';

    return (
      <div>
        <UmnHeader appTitle='U of M Payments' />
        <Router basename={basename}>
          {process.env.REACT_APP_USE_MOCKS === 'true' && App.buildNav()}
          <main>
            <Switch>
              <Route path='/' exact>
                <Redirect to='/customer' />
              </Route>
              <Route path='/version'>
                <Version />
              </Route>
              <Route
                path='/customer/:customer_number/invoices/:invoice_number'
                render={(props) => (
                  <Home
                    customerNumber={props.match.params.customer_number}
                    invoiceNumber={props.match.params.invoice_number}
                  />
                )}
              />
              <Route
                path='/payments/:conf_number'
                render={(props) => (
                  <Confirmation
                    receiptNumber={props.match.params.conf_number}
                  />
                )}
              />
              <Route path='/customer'>
                <Home />
              </Route>
              <Route path='*'>
                <FourOhFour />
              </Route>
            </Switch>
            <ContactFooter />
          </main>
        </Router>
        <UmnFooter />
      </div>
    );
  }
}
