import React from 'react';
import PropTypes from 'prop-types';
import { Row, Container, Col, Button } from 'reactstrap';
import axios from 'axios';
import styled from 'styled-components';
import Config from '../../js/config';
import Error from '../Error';
import Loading from '../Loading';
import EmailButton from '../EmailButton';
import Directions from '../Directions';
import PaymentStatusMessage from '../PaymentStatusMessage';
import PaymentInvoices from '../PaymentInvoices';
import PaymentEmailField from '../PaymentEmailField';

const StyledMetaData = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  button {
    margin-right: 20px;
  }
`;
const ThankYouPrint = styled(Row)`
  font-weight: bold;
  text-align: center;
  font-size: 150%;
  margin-top: 30px;
`;

export default class Confirmation extends React.Component {
  static pendingUpdate() {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loading />
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      confirmedWithApi: false,
      status: '',
      payment: {},
      email: '',
      isEmailButtonDisabled: true,
      showEmailConfirmation: false,
      emailErrors: [],
      currentRetries: 0,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleEmailConfirmationChange =
      this.handleEmailConfirmationChange.bind(this);
  }

  componentDidMount() {
    return this.getPayment();
  }

  handleEmailChange(email = '', isEmailButtonDisabled = false) {
    this.setState({
      email,
      isEmailButtonDisabled,
      showEmailConfirmation: false,
    });
  }

  handleEmailConfirmationChange(showEmailConfirmation = false, errors = []) {
    this.setState({ showEmailConfirmation, emailErrors: errors });
  }

  getPayment() {
    const { receiptNumber, waitInterval, maxRetries } = this.props;
    const { currentRetries } = this.state;
    const data = {}; // data to be sent to endpoint

    let errors = [];
    return axios({
      method: Config.confirmationApiMethod(),
      url: Config.confirmationApiUrl(receiptNumber),
      data,
    })
      .then((response) => {
        let errorArray = [];
        if (
          response.data.payment &&
          response.data.payment.status !== Config.PaymentStatus.COMPLETE &&
          response.data.payment.status !== Config.PaymentStatus.ERROR &&
          currentRetries < maxRetries - 1
        ) {
          setTimeout(() => {
            this.getPayment();
          }, 1000 * waitInterval);
        }
        if (
          response.data.errors !== undefined &&
          response.data.errors.length !== 0
        ) {
          errorArray = response.data.errors.map((error) =>
            Config.getErrorMessageByCode(error)
          );
          errors = errorArray;
        } else {
          this.setState({
            status: response.data.payment.status,
            payment: response.data.payment,
          });
        }
      })
      .catch((error) => {
        errors = [Config.getErrorMessageByStatus(error.response.status)];
      })
      .finally(() => {
        if (process.env.REACT_APP_USE_MOCKS === 'true') {
          setTimeout(() => {
            this.setState({
              confirmedWithApi: true,
              errors,
              currentRetries: currentRetries + 1,
            });
          }, 2000);
        } else {
          this.setState({
            confirmedWithApi: true,
            errors,
            currentRetries: currentRetries + 1,
          });
        }
      });
  }

  listInvoices() {
    const {
      status,
      currentRetries,
      payment,
      emailErrors,
      showEmailConfirmation,
    } = this.state;
    const { maxRetries } = this.props;
    if (status === Config.PaymentStatus.ERROR) {
      return <PaymentStatusMessage status={status} />;
    }
    return (
      <div id='paymentInfo'>
        <div className='no-print'>
          <Directions>
            You may print this page for your records. If you would like an email
            receipt, enter your email address and click &quot;Send email
            receipt&quot;.
          </Directions>
        </div>
        <PaymentStatusMessage
          status={status}
          doneRetry={currentRetries >= maxRetries}
        />

        <PaymentInvoices payment={payment} />
        <PaymentEmailField
          changeEmail={this.handleEmailChange}
          emailErrors={emailErrors}
          showEmailConfirmation={showEmailConfirmation}
        />
        <ThankYouPrint className='print-only-block'>
          <Col>Thank you for your payment!</Col>
        </ThankYouPrint>
      </div>
    );
  }

  confirmationHeader() {
    const { status, confirmedWithApi, errors } = this.state;
    if (
      confirmedWithApi === true &&
      errors.length === 0 &&
      status !== Config.PaymentStatus.ERROR
    ) {
      return (
        <h1 className='no-print' id='thankYouHeader'>
          Thank you for your payment!
        </h1>
      );
    }
    return <h1 id='errorHeader'>Payment Error</h1>;
  }

  render() {
    const { receiptNumber } = this.props;
    const { errors, confirmedWithApi, isEmailButtonDisabled, email, status } =
      this.state;
    return (
      <Container>
        <Row>
          <Col>
            {this.confirmationHeader()}
            <h1 className='print-only-block'>Accounts Receivable Services</h1>
            {errors.length !== 0 && (
              <Error errorArray={errors} title='Oops, something went wrong.' />
            )}
            {errors.length === 0 &&
              confirmedWithApi === false &&
              Confirmation.pendingUpdate()}
            {errors.length === 0 &&
              confirmedWithApi === true &&
              this.listInvoices()}
          </Col>
        </Row>
        <Row>
          <Col>
            {confirmedWithApi === true &&
              errors.length === 0 &&
              status !== Config.PaymentStatus.ERROR && (
                <StyledMetaData id='buttonRow' className='no-print'>
                  <EmailButton
                    disabled={isEmailButtonDisabled}
                    email={email}
                    onEmailConfirmationChange={
                      this.handleEmailConfirmationChange
                    }
                    secureId={receiptNumber}
                  >
                    Print as receipt
                  </EmailButton>
                  <Button color='success' onClick={() => window.print()}>
                    Print as receipt
                  </Button>

                  <a
                    className='btn btn-success'
                    href={process.env.REACT_APP_RETURN_TO_URL}
                  >
                    I&apos;m Finished
                  </a>
                </StyledMetaData>
              )}
          </Col>
        </Row>
      </Container>
    );
  }
}

Confirmation.propTypes = {
  receiptNumber: PropTypes.string.isRequired,
  maxRetries: PropTypes.number,
  waitInterval: PropTypes.number,
};
Confirmation.defaultProps = {
  maxRetries: 6,
  waitInterval: 10,
};
