import React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Button,
} from 'reactstrap';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import Directions from '../Directions';
// import SearchButton from '../SearchButton';
import Error from '../Error';

const labelWidth = 3;
const valueWidth = 4;

const StyledLabel = styled(Label)`
  font-weight: bold;
  &:after {
    content: ':';
  }
  &:before {
    content: '* ';
  }
`;

const StyledForm = styled(Form)`
  margin-left: 75px;

  #requiredInfo {
    font-style: italic;
    &:before {
      content: '* ';
    }
  }
`;

const StyledInputFields = styled.div`
  margin-top: 30px;
  padding-bottom: 30px;
`;
export default class EnterCustomerNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerNumber: props.customerNumber,
      invoiceId: props.invoiceNumber,
      isCustomerNumberInvalid: false,
      isInvoiceNumberInvalid: false,
    };
    this.handleCustomerNumberChange =
      this.handleCustomerNumberChange.bind(this);
    this.handleInvoiceIdChange = this.handleInvoiceIdChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleCustomerNumberChange(event) {
    const newVal = event.target.value;
    const newValString = newVal.toString();
    const isInvalid = newValString.length > 0 && newValString.length !== 7;

    this.setState({
      customerNumber: newVal,
      isCustomerNumberInvalid: isInvalid,
    });
  }

  handleInvoiceIdChange(event) {
    const newVal = event.target.value;
    const newValString = newVal.toString();
    const isInvalid =
      newValString.length > 0 &&
      newValString.length !== 10 &&
      newValString.length !== 11 &&
      newValString.length !== 12;
    this.setState({ invoiceId: newVal, isInvoiceNumberInvalid: isInvalid });
  }

  handleButtonClick() {
    const { onInvoiceSearch } = this.props;
    const { customerNumber, invoiceId } = this.state;
    onInvoiceSearch(customerNumber, invoiceId);
  }

  render() {
    const {
      customerNumber,
      invoiceId,
      isCustomerNumberInvalid,
      isInvoiceNumberInvalid,
    } = this.state;
    const { errors } = this.props;
    return (
      <Container>
        <Row>
          <Col>
            <h1> Step 1: Enter Invoice Information </h1>
            <Directions>
              Help us locate your records by entering the Customer Number and
              Invoice Number from your University of Minnesota invoice and then
              click &quot;Continue&quot; The information you enter will be
              validated against University of Minnesota records. If all of the
              information is correct, all open invoices for this customer record
              will be displayed.
            </Directions>
          </Col>
        </Row>

        <StyledForm>
          {errors.length !== 0 && (
            <Row>
              <Col>
                <Error title='' errorArray={errors} />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <p id='requiredInfo'>All fields required</p>
            </Col>
          </Row>
          <StyledInputFields>
            <FormGroup row>
              <StyledLabel for='customerId' md={labelWidth}>
                Customer Number
              </StyledLabel>
              <Col md={valueWidth}>
                <Input
                  type='text'
                  name='customerId'
                  id='customerId'
                  placeholder=''
                  onChange={this.handleCustomerNumberChange}
                  invalid={isCustomerNumberInvalid}
                  value={customerNumber}
                  required
                />
                <FormFeedback>
                  Please enter your 7 digit Customer ID
                </FormFeedback>
              </Col>
            </FormGroup>
            <FormGroup row>
              <StyledLabel for='invoiceNumber' md={labelWidth}>
                Invoice Number
              </StyledLabel>
              <Col md={valueWidth}>
                <Input
                  type='text'
                  name='invoiceNumber'
                  id='invoiceNumber'
                  placeholder=''
                  onChange={this.handleInvoiceIdChange}
                  invalid={isInvoiceNumberInvalid}
                  value={invoiceId}
                  required
                />
                <FormFeedback>
                  Please enter a 10 - 12 digit invoice number
                </FormFeedback>
              </Col>
            </FormGroup>
          </StyledInputFields>
          <FormGroup row>
            <Button
              id='continueButton'
              color='success'
              disabled={
                isCustomerNumberInvalid ||
                isInvoiceNumberInvalid ||
                customerNumber.toString().length === 0 ||
                invoiceId.toString().length === 0
              }
              onClick={this.handleButtonClick}
            >
              Continue
            </Button>
          </FormGroup>
        </StyledForm>
      </Container>
    );
  }
}

EnterCustomerNumber.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  customerNumber: PropTypes.string,
  invoiceNumber: PropTypes.string,
  onInvoiceSearch: PropTypes.func,
};
EnterCustomerNumber.defaultProps = {
  errors: [],
  customerNumber: '',
  invoiceNumber: '',
  onInvoiceSearch: () => {},
};
