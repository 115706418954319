import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import Config from '../js/config';

function PaymentStatusMessage(props) {
  const { status, doneRetry } = props;
  if (status === Config.PaymentStatus.COMPLETE) {
    return (
      <Alert color='success'>
        This invoice has been paid and verified by the University of Minnesota.
      </Alert>
    );
  }

  if (status === Config.PaymentStatus.ERROR) {
    return (
      <Alert color='danger'>
        Payment error, please contact
        <a className='alert-link' href='mailto:arpaymnt@umn.edu'>
          arpaymnt@umn.edu
        </a>
      </Alert>
    );
  }

  return (
    <Alert color='warning' id='pendingWarning'>
      Just a moment, we are updating our records!{' '}
      {doneRetry === true && <div id='pleaseRefreshText'>Please refresh!</div>}
    </Alert>
  );
}
PaymentStatusMessage.propTypes = {
  status: PropTypes.string.isRequired,
  doneRetry: PropTypes.bool,
};
PaymentStatusMessage.defaultProps = {
  doneRetry: false,
};
export default PaymentStatusMessage;
