import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCol = styled(Col)`
  font-weight: bold;
  &:after {
    content: ':';
  }
`;

const StyledValueCol = styled(Col)`
  margin-bottom: 15px;
`;

const StyledMetaData = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  button {
    margin-right: 20px;
  }
`;
const dataLabelColWidth = '3';

function PaymentInvoices(props) {
  const { payment } = props;
  const payObj = {
    customerNumber: payment.customer_number,
    customerName: payment.customer_name,
    paidAmount: payment.paid_amount,
    status: payment.status,
    updatedAt: payment.updated_at,
    lineItems: payment.line_items,
    transactionId: payment.transaction_id,
    paymentMethod: payment.payment_method,
  };
  return (
    <div>
      <StyledMetaData>
        <Row>
          <StyledCol sm={dataLabelColWidth}>Customer ID</StyledCol>
          <StyledValueCol id='customerNumber'>
            {payObj.customerNumber}
          </StyledValueCol>
        </Row>

        <Row>
          <StyledCol sm={dataLabelColWidth}>Customer Name</StyledCol>
          <StyledValueCol id='customerName'>
            {payObj.customerName}
          </StyledValueCol>
        </Row>
        <Row>
          <StyledCol sm={dataLabelColWidth}>Transaction ID</StyledCol>
          <StyledValueCol id='transactionId'>
            {payObj.transactionId}
          </StyledValueCol>
        </Row>
        <Row>
          <StyledCol sm={dataLabelColWidth}>Payment Method</StyledCol>
          <StyledValueCol id='paymentMethod'>
            {payObj.paymentMethod}
          </StyledValueCol>
        </Row>

        <Row>
          <StyledCol sm={dataLabelColWidth}> Total</StyledCol>
          <StyledValueCol id='paidAmount'>{payObj.paidAmount}</StyledValueCol>
        </Row>
        <Row>
          <StyledCol sm={dataLabelColWidth}>Date</StyledCol>
          <StyledValueCol id='paidDate'>{payObj.updatedAt}</StyledValueCol>
        </Row>
      </StyledMetaData>
      <Table striped>
        <thead>
          <tr>
            <th scope='col'>Invoice Number</th>
            <th scope='col'>Invoice Date</th>
            <th scope='col'>Invoice Amount</th>
          </tr>
        </thead>
        <tbody>
          {payObj.lineItems.map((item) => (
            <tr className='invoiceRow' key={item.invoice_number}>
              <td className='invoiceRowInvoiceNumber'>{item.invoice_number}</td>
              <td className='invoiceRowInvoiceDate'>{item.date_invoiced}</td>
              <td className='invoiceRowInvoiceAmount'>{item.amount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
PaymentInvoices.propTypes = {
  payment: PropTypes.instanceOf(Object),
};
PaymentInvoices.defaultProps = {
  payment: {},
};
export default PaymentInvoices;
