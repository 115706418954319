import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Alert, Input, Label } from 'reactstrap';
import styled from 'styled-components';

import Error from './Error';

const StyledCol = styled(Col)`
  font-weight: bold;
  &:after {
    content: ':';
  }
`;

export default class PaymentEmailField extends React.Component {
  static isEmailValid(email) {
    // Reg ex is from: https://www.w3resource.com/javascript/form/email-validation.php
    // Disabling the line below because linter is incorrectly stating escapes are not necessary - yet they are
    // eslint-disable-next-line no-useless-escape
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isEmailInValid: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.showEmailConfirmation = this.showEmailConfirmation.bind(this);
    // this.handleEmailConfirmationChange = this.handleEmailConfirmationChange.bind(
    //   this
    // );
  }

  handleEmailChange(event) {
    const { changeEmail } = this.props;
    const enteredEmail = event.target.value;

    let isEmailInValid = true;
    let isEmailButtonDisabled = true;

    if (enteredEmail === '') {
      isEmailInValid = false;
      isEmailButtonDisabled = true;
    } else if (PaymentEmailField.isEmailValid(enteredEmail)) {
      isEmailInValid = false;
      isEmailButtonDisabled = false;
    } else {
      isEmailInValid = true;
      isEmailButtonDisabled = true;
    }

    changeEmail(enteredEmail, isEmailButtonDisabled);
    this.setState({
      email: enteredEmail,
      isEmailInValid,
    });
  }

  showEmailConfirmation() {
    const { emailErrors, showEmailConfirmation } = this.props;

    if (showEmailConfirmation === false) {
      return '';
    }
    if (emailErrors.length === 0) {
      return <Alert color='success'>Email successfully sent.</Alert>;
    }
    return <Error errorArray={emailErrors} title='' />;
  }

  render() {
    const { email, isEmailInValid } = this.state;
    return (
      <Row className='no-print'>
        <StyledCol sm='2'>
          <Label htmlFor='emailInputField'>Email address</Label>
        </StyledCol>
        <Col sm='4'>
          <Input
            id='emailInputField'
            value={email}
            onChange={this.handleEmailChange}
            invalid={isEmailInValid}
          />
        </Col>
        <Col xs='6'>{this.showEmailConfirmation()}</Col>
      </Row>
    );
  }
}

PaymentEmailField.propTypes = {
  changeEmail: PropTypes.func,
  emailErrors: PropTypes.arrayOf(PropTypes.string),
  showEmailConfirmation: PropTypes.bool,
};
PaymentEmailField.defaultProps = {
  changeEmail: (/* emailAddress,  isEmailButtonDisabled */) => {},
  emailErrors: [],
  showEmailConfirmation: false,
};
