import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function FourOhFour() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Page not found</h1>
          <p>The request page could not be found.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default FourOhFour;
