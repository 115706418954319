/* If time allows, it would also be nice to include an ajax call back to the
rails app to get the version of the rails app on this page */
import React from 'react';
import styled from 'styled-components';

const Footer = styled.div`
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 52px;
  border-bottom: 2px solid rgb(255, 153, 51);
  border-top: 2px solid rgb(255, 153, 51);
  text-align: center;
  font-style: italic;
  margin-top: 30px;
  margin-bottom: 0px;
  background: linear-gradient(rgb(240, 233, 209) 0%, rgb(255, 222, 124) 100%);
  a {
    color: #7a0019;
  }
`;

function ContactFooter() {
  return (
    <Footer>
      For technical help, please contact Accounts Receivable Services at
      612-625-2392 or email arpaymnt@umn.edu
      <div className='no-print'>
        <a href='https://pay.umn.edu'>Help</a>
      </div>
    </Footer>
  );
}

export default ContactFooter;
