import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';
import InvoiceModal from './InvoiceModal';

export default class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
  }

  handleCheckBoxClick(event) {
    const { changeCheckBox, invoiceNumber } = this.props;
    changeCheckBox(invoiceNumber, event.target.checked);
  }

  render() {
    const { invoiceNumber, checked, date, amount, eligible } = this.props;
    return (
      <tr key={invoiceNumber}>
        <td>
          <Input
            style={{ position: 'relative', marginLeft: '20px' }}
            type='checkbox'
            onClick={this.handleCheckBoxClick}
            disabled={!eligible}
            id={`cb_${invoiceNumber}`}
            defaultChecked={checked}
          />
          <Label className='sr-only' htmlFor={`cb_${invoiceNumber}`}>
            Pay invoice
            {invoiceNumber}
          </Label>
        </td>
        <td>{invoiceNumber}</td>
        <td>{date}</td>
        <td>{amount}</td>
        <td>
          {eligible === false && (
            <InvoiceModal
              isAmountLessThanZero={parseInt(amount.replace('$', ''), 10) < 0}
            />
          )}
        </td>
      </tr>
    );
  }
}

Invoice.propTypes = {
  invoiceNumber: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  eligible: PropTypes.bool,
  date: PropTypes.string,
  checked: PropTypes.bool,
  changeCheckBox: PropTypes.func,
};
Invoice.defaultProps = {
  eligible: true,
  date: '',
  checked: false,
  changeCheckBox: () => {},
};
