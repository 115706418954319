import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import Config from '../js/config';
import Error from './Error';
import Loading from './Loading';

export default class CybersourcePayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      cyberSourceData: '',
      cyberSourceURL: '',
      dataLoaded: false,
    };
  }

  componentDidMount() {
    const { invoices, forwardMe, customerNumber, leadingInvoice } = this.props;

    const data = {
      customer: {
        customer_number: customerNumber,
        leading_invoice: leadingInvoice,
        invoices,
      },
    };
    return axios({
      method: Config.cyberSourceDataApiMethod(),
      url: Config.cyberSourceDataApiUrl(),
      data,
    })
      .then((response) => {
        let errorArray = [];
        if (
          response.data.errors !== undefined &&
          response.data.errors.length > 0
        ) {
          errorArray = response.data.errors.map((error) =>
            Config.getErrorMessageByCode(error)
          );
          this.setState({ errors: errorArray });
        } else {
          const cyberSourceURL = response.data.cybersource_url;

          this.setState({
            cyberSourceURL,
            cyberSourceData: response.data,
            dataLoaded: true,
          });

          if (forwardMe === true) {
            document.getElementById('formCyberSourceData').submit();
          }
        }
      })
      .catch((error) => {
        this.setState({
          errors: [Config.getErrorMessageByStatus(error.response.status)],
        });
      });
  }

  render() {
    // TODO There appears to be an issue with reactstrap's modal.  It throws 3 errors
    const { errors, cyberSourceData, cyberSourceURL, dataLoaded } = this.state;
    const lineItems = cyberSourceData.line_items;
    const lineItemInputs = [];
    if (lineItems) {
      lineItems.forEach((item) => {
        lineItemInputs.push(
          <div key={item.item_name_field}>
            <input
              type='hidden'
              name={item.item_name_field}
              id={item.item_name_field}
              value={item.item_name_value}
            />
            <input
              type='hidden'
              name={item.item_unit_price_field}
              id={item.item_unit_price_field}
              value={item.item_unit_price_value}
            />
          </div>
        );
      });
    }

    return (
      <Modal isOpen>
        <ModalHeader>Preparing your payment</ModalHeader>
        <ModalBody>
          {errors.length > 0 && <Error errorArray={errors} />}
          {errors.length === 0 && (
            <div>
              <p style={{ textAlign: 'center' }}>
                <Loading />
              </p>
              <p>You will be redirected to CyberSource shortly.</p>
            </div>
          )}
          {dataLoaded === true ? (
            <form
              className='sr-only'
              method='post'
              action={cyberSourceURL}
              id='formCyberSourceData'
              name='formCyberSourceData'
            >
              <input
                type='hidden'
                name='access_key'
                id='access_key'
                value={cyberSourceData.access_key}
              />
              <input
                type='hidden'
                name='profile_id'
                id='profile_id'
                value={cyberSourceData.profile_id}
              />
              <input
                type='hidden'
                name='transaction_uuid'
                id='transaction_uuid'
                value={cyberSourceData.transaction_uuid}
              />
              <input
                type='hidden'
                name='signed_field_names'
                id='signed_field_names'
                value={cyberSourceData.signed_field_names}
              />
              <input
                type='hidden'
                name='unsigned_field_names'
                id='unsigned_field_names'
                value={cyberSourceData.unsigned_field_names}
              />
              <input
                type='hidden'
                name='signed_date_time'
                id='signed_date_time'
                value={cyberSourceData.signed_date_time}
              />
              <input
                type='hidden'
                name='locale'
                id='locale'
                value={cyberSourceData.locale}
              />
              <input
                type='hidden'
                name='transaction_type'
                id='transaction_type'
                value={cyberSourceData.transaction_type}
              />
              <input
                type='hidden'
                name='reference_number'
                id='reference_number'
                value={cyberSourceData.reference_number}
              />
              <input
                type='hidden'
                name='amount'
                id='amount'
                value={cyberSourceData.amount}
              />
              <input
                type='hidden'
                name='currency'
                id='currency'
                value={cyberSourceData.currency}
              />
              <input
                type='hidden'
                name='line_item_count'
                id='line_item_count'
                value={cyberSourceData.line_item_count}
              />
              {lineItemInputs}
              <input
                type='hidden'
                name='signature'
                id='signature'
                value={cyberSourceData.signature}
              />
              <submit />
            </form>
          ) : null}
        </ModalBody>
      </Modal>
    );
  }
}

CybersourcePayment.propTypes = {
  leadingInvoice: PropTypes.string,
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      customer_name: PropTypes.string,
      customer_number: PropTypes.string,
      invoice_number: PropTypes.string,
      eligible: PropTypes.bool,
      date_invoiced: PropTypes.string,
      amount_due: PropTypes.string,
    })
  ),
  forwardMe: PropTypes.bool, // This is only used for testing
  customerNumber: PropTypes.string.isRequired,
};
CybersourcePayment.defaultProps = {
  leadingInvoice: '',
  invoices: [],
  forwardMe: true,
};
