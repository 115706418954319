/* If time allows, it would also be nice to include an ajax call back to the
rails app to get the version of the rails app on this page */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function Version() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Version</h1>
          <ul>
            <li>
              <strong>Application Name:</strong>
              <span id='appNameFromPackageJson'>
                {process.env.REACT_APP_NAME}
              </span>
            </li>
            <li>
              <strong>Version: </strong>
              <span id='appVersionFromPackageJson'>
                {process.env.REACT_APP_VERSION}
              </span>
            </li>
            <li>
              <strong>Git Commit: </strong>
              <span id='gitCommitHash'>{process.env.REACT_APP_GIT_SHA}</span>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Version;
