import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #333;
`;
function Directions(props) {
  const { children } = props;
  return <StyledCard body>{children}</StyledCard>;
}

export default Directions;

Directions.propTypes = {
  children: PropTypes.node,
};

Directions.defaultProps = {
  children: '',
};
