import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import styled from 'styled-components';

const StyledAlert = styled(Alert)`
  p:last-child {
    margin-bottom: 0;
  }
`;

function Error(props) {
  const { errorArray, title } = props;

  return (
    // TODO There appears to be an error in the Alert Component.  Switch this out with a custom component
    <StyledAlert color='danger'>
      {title !== '' && <h2 className='h5'>{title}</h2>}
      {errorArray.map((error, index) => (
        // eslint-disable-next-line react/no-danger, react/no-array-index-key
        <div key={index} dangerouslySetInnerHTML={{ __html: error }} />
      ))}
    </StyledAlert>
  );
}
export default Error;

Error.propTypes = {
  errorArray: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

Error.defaultProps = {
  errorArray: [],
  title: 'Error',
};
