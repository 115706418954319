import React from 'react';
import PropTypes from 'prop-types';
import EnterCustomerNumber from './EnterCustomerNumber';
import SelectInvoices from './SelectInvoices';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerNumber: props.customerNumber,
      invoiceNumber: props.invoiceNumber,
      searchErrors: props.errors,
    };
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
  }

  handleCustomerChange(customerNumber, invoiceNumber, searchErrors = []) {
    this.setState({ customerNumber, invoiceNumber, searchErrors });
    if (
      customerNumber !== '' &&
      invoiceNumber !== '' &&
      searchErrors.length === 0
    ) {
      const state = {};
      const title = '';
      const url = `/customer/${customerNumber}/invoices/${invoiceNumber}`;

      // eslint-disable-next-line no-restricted-globals
      history.replaceState(state, title, url);
    } else {
      // eslint-disable-next-line no-restricted-globals
      history.replaceState({}, '', '/customer');
    }
  }

  render() {
    const { customerNumber, invoiceNumber, searchErrors } = this.state;
    if (
      customerNumber === '' ||
      invoiceNumber === '' ||
      searchErrors.length !== 0
    ) {
      return (
        <EnterCustomerNumber
          customerNumber={customerNumber}
          invoiceNumber={invoiceNumber}
          errors={searchErrors}
          onInvoiceSearch={this.handleCustomerChange}
        />
      );
    }
    return (
      <SelectInvoices
        customerNumber={customerNumber}
        invoiceNumber={invoiceNumber}
        onInvoiceSearch={this.handleCustomerChange}
      />
    );
  }
}

Home.propTypes = {
  customerNumber: PropTypes.string,
  invoiceNumber: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
};
Home.defaultProps = {
  customerNumber: '',
  invoiceNumber: '',
  errors: [],
};
