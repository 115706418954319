/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import styled from 'styled-components';

const StyledEligibleLink = styled(Button)`
  margin: 0px;
  padding: 0px;
`;

function InvoiceModal(props) {
  const { isAmountLessThanZero } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const modalText = () => {
    if (isAmountLessThanZero === true) {
      return <p>Please call 612-625-2392.</p>;
    }
    return (
      <p>
        The amount of the invoice is
        <em> greater </em>
        than the dollar limit allowed by the Department that provided the goods
        or services billed on this invoice. For information on other payment
        options go to
        <span>
          {' '}
          <a
            href='http://pay.umn.edu'
            target='_blank'
            rel='noopener noreferrer'
          >
            pay.umn.edu
          </a>
        </span>
      </p>
    );
  };

  return (
    <div>
      <StyledEligibleLink color='link' onClick={toggle}>
        Why can&apos;t I select this invoice?
      </StyledEligibleLink>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <p>
            <strong>
              {isAmountLessThanZero === true
                ? 'The credit invoice cannot be selected to net against your current payment.'
                : 'This invoice cannot be paid by credit card.'}
            </strong>
          </p>
          {modalText()}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default InvoiceModal;
