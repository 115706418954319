import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import axios from 'axios';
import Config from '../js/config';

export default class EmailButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick() {
    const { secureId, email, onEmailConfirmationChange } = this.props;
    let errors = [];

    this.setState({ loading: true });
    onEmailConfirmationChange(false, errors); // hide confirmation/error message

    const data = {
      secure_id: secureId,
      email_address: email,
    };
    return axios({
      method: Config.emailReceiptApiMethod(),
      url: Config.emailReceiptApiUrl(),
      data,
    })
      .then((response) => {
        if (
          response.data.errors !== undefined &&
          response.data.errors.length > 0
        ) {
          errors = response.data.errors.map((error) =>
            Config.getErrorMessageByCode(error)
          );
        }
      })
      .catch((error) => {
        errors = [Config.getErrorMessageByStatus(error.response.status)];
      })
      .finally(() => {
        this.setState({ loading: false });
        onEmailConfirmationChange(true, errors);
      });
  }

  render() {
    const { disabled } = this.props;
    const { loading } = this.state;
    return (
      <Button
        color='success'
        disabled={disabled || loading}
        onClick={this.handleButtonClick}
      >
        Send email receipt
      </Button>
    );
  }
}

EmailButton.propTypes = {
  disabled: PropTypes.bool,
  secureId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onEmailConfirmationChange: PropTypes.func,
};
EmailButton.defaultProps = {
  disabled: true,
  onEmailConfirmationChange: () => {},
};
