/* Body of "Step 2 page" where the user selects an invoice */

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Col, Container, Row, Table } from 'reactstrap';
import axios from 'axios';
import Dinero from 'dinero.js';
import styled from 'styled-components';
import config from '../../js/config';
import Loading from '../Loading';
import Error from '../Error';
import Invoice from '../Invoice';
import Directions from '../Directions';
import CybersourcePayment from '../CybersourcePayment';

const InvoicesFor = styled.p`
  color: #1a7a30;
  font-weight: bold;
`;
const ButtonRow = styled.div`
  button {
    margin-right: 20px;
  }
`;

const TransferredAlert = styled(Alert)`
  font-style: italic;
  margin-top: 30px;
`;

export default class SelectInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoicesLoaded: false,
      customerName: '',
      errors: [],
      invoices: [],
      selectedInvoices: [],
      payTotal: Dinero({ amount: 0 }),
      getToken: false,
      propInvoiceIsDisabled: false,
      propInvoiceIsUnpayable: false,
    };
    this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
    this.updatePayTotal = this.updatePayTotal.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleCancelButton = this.handleCancelButton.bind(this);
  }

  componentDidMount() {
    const { customerNumber, invoiceNumber, doNotLoad, onInvoiceSearch } =
      this.props;
    if (doNotLoad === true) {
      return null;
    }
    return axios({
      method: 'get',
      url: config.invoiceApiUrl(customerNumber, invoiceNumber),
    })
      .then((response) => {
        const customerJSON = response.data.customer;
        let errorArray = [];
        let invalidInvoice = false;
        let propInvoiceIsUnpayable = false;

        if (response.data.errors !== undefined) {
          const searchErrors = [];

          errorArray = response.data.errors.reduce((tempArray, error) => {
            if (error.code !== 'E08' && error.code !== 'E09') {
              tempArray.push(config.getErrorMessageByCode(error));
            } else if (error.code === 'E08') {
              invalidInvoice = true;
            } else {
              propInvoiceIsUnpayable = true;
            }
            if (
              error.code === 'E01' ||
              error.code === 'E02' ||
              error.code === 'E03'
            ) {
              searchErrors.push(config.getErrorMessageByCode(error));
            }
            return tempArray;
          }, []);

          onInvoiceSearch(customerNumber, invoiceNumber, searchErrors);
        }
        let customerName = '';

        if (
          customerJSON.invoice[0] !== undefined &&
          customerJSON.invoice[0].customer_name
        ) {
          customerName = customerJSON.invoice[0].customer_name;
        }
        const sentInvoice = customerJSON.invoice.find(
          (invoice) => invoice.invoice_number === invoiceNumber
        );

        let eligibleInvoice = false;
        if (sentInvoice !== undefined) {
          eligibleInvoice = sentInvoice.eligible;
        }
        const propInvoiceIsDisabled =
          invalidInvoice === true || eligibleInvoice === false;

        this.setState(
          {
            invoices: customerJSON.invoice,
            customerName,
            errors: errorArray,
            selectedInvoices:
              sentInvoice !== undefined && sentInvoice.eligible
                ? [sentInvoice]
                : [],
            propInvoiceIsDisabled,
            propInvoiceIsUnpayable,
          },
          () => {
            this.updatePayTotal();
          }
        );
      })
      .catch((error) => {
        this.setState({
          errors: [config.getErrorMessageByStatus(error.response.status)],
        });
      })
      .finally(() => {
        this.setState({ invoicesLoaded: true });
      });
  }

  handleButtonClick() {
    this.setState({ getToken: true });
  }

  handleCancelButton() {
    const { onInvoiceSearch } = this.props;
    onInvoiceSearch('', '');
  }

  handleCheckBoxClick(invoiceNumber, isChecked) {
    const { selectedInvoices, invoices } = this.state;

    const tempSelectedInvoices = [...selectedInvoices];
    const index = tempSelectedInvoices.findIndex(
      (invoice) => invoiceNumber === invoice.invoice_number
    );
    if (isChecked === false && index !== -1) {
      tempSelectedInvoices.splice(index, 1);
    }
    if (isChecked === true && index === -1) {
      const invoiceToAdd = invoices.find(
        (invoice) => invoice.invoice_number === invoiceNumber
      );
      tempSelectedInvoices.push(invoiceToAdd);
    }

    if (selectedInvoices.length !== tempSelectedInvoices.length) {
      this.setState({ selectedInvoices: tempSelectedInvoices }, () => {
        this.updatePayTotal();
      });
    }
  }

  updatePayTotal() {
    const { selectedInvoices } = this.state;

    const total = selectedInvoices.reduce((totalDinero, invoice) => {
      const invoiceAmount = parseInt(
        parseFloat(invoice.amount_due.replace('$', '').replace(',', '')) * 100,
        10
      );
      return totalDinero.add(Dinero({ amount: invoiceAmount }));
    }, Dinero({ amount: 0 }));
    this.setState({ payTotal: total });
  }

  selectedDisabledMessage() {
    const { propInvoiceIsUnpayable } = this.state;
    const { propInvoiceIsDisabled } = this.state;
    if (propInvoiceIsUnpayable === true) {
      return (
        <Alert color='info' id='enteredInvoiceNotPayableMessage'>
          <div>
            The invoice you entered cannot be paid by credit card. The
            University of Minnesota Department that provided the goods or
            services billed on this invoice does not currently accept credit
            card payments. For information on other payment options please go to
            <span> </span>
            <a className='alert-link' href='http://pay.umn.edu'>
              Pay.umn.edu
            </a>
          </div>
        </Alert>
      );
    }
    if (propInvoiceIsDisabled === true) {
      return (
        <Alert color='info' id='enteredInvoiceNotEligibleMessage'>
          <div>
            <strong>Note: </strong>
            We&apos;re sorry, but the invoice that you entered cannot currently
            be paid through this on-line process. Please contact Accounts
            Receivable Services at 612-625-2392 during normal business hours and
            someone will assist you.
          </div>
        </Alert>
      );
    }
    return null;
  }

  invoiceTable() {
    const { invoiceNumber } = this.props;
    const { customerName, invoices, payTotal, getToken, selectedInvoices } =
      this.state;

    const disablePay = selectedInvoices.length === 0;
    return (
      <Container>
        <Row>
          <Col>
            <h1> Step 2: Invoice Selection</h1>
            <Directions>
              <p>
                You may pay multiple invoices. The invoice you entered in Step 1
                will automatically be selected for payment. Select additional
                invoices to be paid by checking the appropriate box next to the
                Invoice Number.
              </p>
              <p>
                All on-line payments must be made in full. If you would like to
                make a partial payment, please contact Accounts Receivable
                Services at 612-625-2392 during regular business hours.
              </p>
            </Directions>

            {this.selectedDisabledMessage()}
            <InvoicesFor>
              Invoice
              {invoices.length > 0 ? 's ' : ' '}
              <span>for </span>
              {customerName}
            </InvoicesFor>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped>
              <thead>
                <tr>
                  <th>Pay</th>
                  <th>Invoice Number</th>
                  <th>Invoice Date</th>
                  <th>Amount Due</th>
                  <th>Additional Info</th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((invoice) => (
                  <Invoice
                    key={invoice.invoice_number}
                    invoiceNumber={invoice.invoice_number}
                    date={invoice.date_invoiced}
                    amount={invoice.amount_due}
                    eligible={invoice.eligible}
                    changeCheckBox={this.handleCheckBoxClick}
                    checked={
                      invoice.invoice_number === invoiceNumber &&
                      invoice.eligible
                    }
                  />
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>Total Payment: </strong>
              {payTotal.toFormat('$0,0.00')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            {getToken === false && (
              <ButtonRow>
                <Button
                  id='payInvoice'
                  onClick={this.handleButtonClick}
                  color='success'
                  disabled={disablePay}
                  style={{ fontWeight: 'bold' }}
                >
                  Pay selected invoices
                </Button>

                <Button
                  color='success'
                  role='link'
                  onClick={this.handleCancelButton}
                >
                  Cancel
                </Button>
              </ButtonRow>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <TransferredAlert color='warning'>
              You will be transferred to CyberSource to complete your payment
              verification and processing.
            </TransferredAlert>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const {
      invoicesLoaded,
      errors,
      getToken,
      selectedInvoices,
      // redirect,
    } = this.state;
    const { customerNumber, invoiceNumber } = this.props;
    return (
      <Container>
        <Row>
          <Col>
            {errors.length > 0 && <Error errorArray={errors} />}
            {invoicesLoaded === false && <Loading />}
            {invoicesLoaded === true &&
              errors.length === 0 &&
              this.invoiceTable()}
            {getToken === true && (
              <CybersourcePayment
                leadingInvoice={invoiceNumber}
                invoices={selectedInvoices}
                customerNumber={customerNumber}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

SelectInvoices.propTypes = {
  customerNumber: PropTypes.string.isRequired,
  invoiceNumber: PropTypes.string,
  doNotLoad: PropTypes.bool, // this is only used for testing
  onInvoiceSearch: PropTypes.func,
};
SelectInvoices.defaultProps = {
  invoiceNumber: '',
  doNotLoad: false,
  onInvoiceSearch: () => {},
};
